.main-mobile {
  display: none;
  margin-top: 100px;
  .hero-main {
    // display: flex;
    background: #fafcfc;

    .image-section {
      img {
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        border-radius: 12px;
        height: 100%;
      }

      h2 {
        background: rgba(0, 0, 0, 0.5);
        margin-bottom: 0;
        border-radius: 8px 8px 0 0;
        padding: 10px;
        font-size: 18px;
        font-weight: 800;
      }

      p {
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        margin-bottom: 0;
        width: 100%;

        // font-weight: 500;
      }

      .btn-mobile {
        display: flex;
        justify-content: center;
        // border: 1px solid black;

        a {
          border: none;
          font-size: 14px;
          color: #ffffff;
          background: #00009f;
          border-radius: 10px;
          padding: 4px 35px;
          font-style: italic;
          text-decoration: none;
        }
      }
    }
  }
}

// @media screen and (max-width: 768px) {
//   .main-mobile {
//     display: block;
//   }
// }

@media screen and (max-width: 768px) {
  .main-mobile {
    display: block;

    .hero-main {
      .image-section {
        h2 {
          background: rgba(0, 0, 0, 0.5);
          margin-bottom: 0;
          border-radius: 8px 8px 0 0;
          padding: 10px;
          font-size: 18px;
          font-weight: 700;
        }

        p {
          background: rgba(0, 0, 0, 0.5);
          padding: 2px;
          margin-bottom: 0;
          width: 100%;
          font-size: 12px;
          // font-weight: 500;
        }

        .btn-mobile {
          display: flex;
          justify-content: center;

          button {
            border: none;
            font-size: 14px;
            color: #ffffff;
            background: #00009f;
            border-radius: 10px;
            padding: 4px 35px;
            font-style: italic;
          }
        }
      }
    }
  }
}
