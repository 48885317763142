.review-main {
  margin-top: 30px;
  padding-bottom: 70px;

  .container {
    .reviews-header {
      margin: 10px 0 10px 0;
      h3 {
        text-align: center;
        color: #00009f;
        font-weight: 700;
        font-size: 38px;
        font-style: italic;
      }
    }
    .reviews {
      width: 50%;
      margin: auto;
      padding: 20px;

      font-size: 18px;
      h4 {
        font-weight: 700;
      }

      .each-slide-effect {
        padding: 20px;
        font-size: 20px;
        background: #efefef;
        text-align: center;
        border-radius: 10px;
        font-style: italic;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .review-main {
    margin-top: 70px;
    padding-bottom: 70px;

    .container {
      .reviews-header {
        margin: 30px 0 30px 0;
        h2 {
          text-align: center;
          color: #00009f;
          font-weight: 700;
          font-size: 38px;
        }
      }
      .reviews {
        width: 100%;
        margin: auto;
        padding: 20px;

        font-size: 18px;
        h4 {
          font-weight: 700;
        }

        .each-slide-effect {
          padding: 20px;
          font-size: 20px;
          background: #efefef;
          text-align: center;
        }
      }
    }
  }
}
