.background-video-container {
  margin-top: 50px;
  padding: 25px;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;

  a {
    color: #070707;
    :hover {
      color: #00009f;
    }
  }

  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  .content-overlay {
    position: relative;
    z-index: 1;
    color: white;
    // text-align: center;
    padding: 20px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;

    .containe {
      // background: #000;
      width: 100%;
      .averta-section {
        display: flex;
        justify-content: space-between;

        .notifications {
          font-size: 28px;
          color: #00009f;
        }
      }

      .dashboard-body {
        margin-top: 10px;
        overflow-x: hidden;
        
        .scroll-section {
          
          .row {
            
            .col-md-12 {
              background: #ffffff;
              padding: 20px;

              .dashboard-quick-links {
                margin-top: 10px;
                border-radius: 10px;
                justify-content: center;

                .row {
                  .col-md-4 {
                    .user-balance {
                      border: 1px solid rgb(224, 223, 223);
                      padding: 10px;
                      background: #ffffff;
                      border-radius: 10px;
                      margin: 2px;
                      font-size: 18px;

                      a {
                        display: flex;
                        text-decoration: none;
                      }
                      // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                      // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    }
                  }
                }
              }
              .payments {
                border: 1px solid rgb(224, 223, 223);
                margin-top: 10px;
                background: #ffffff;
                padding: 20px;
                border-radius: 10px;

                .row {
                  .col-md-4 {
                    a {
                      text-decoration: none;
                      .dollar-section,
                      .pounds-section {
                        border-right: 1px solid #00009f;
                      }
                    }
                  }
                }
              }

              .transactions {
                border: 1px solid rgb(224, 223, 223);
                margin-top: 10px;
                background: #ffffff;
                padding: 20px;
                border-radius: 10px;
              }
            }
          }
        }

        .btn-creat-acct-section {
          display: flex;
          justify-content: flex-start;
          padding-right: 50px;
          margin-bottom: 10px;
          .btn-createAccount {
            border: 1px solid #00009f;
            text-align: center;
            border-radius: 5px;
            background: #00009f;
            padding: 5px;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
    }
  }

  .modal-container {
    .modal-title {
      text-align: center;
      margin-bottom: 20px;
    }
    .modal-body {
      .btn-creat-acct-section {
        display: flex;
        justify-content: flex-start;
        padding-right: 50px;
        margin-bottom: 20px;
        .btn-createAccount {
          border: 1px solid #00009f;
          text-align: center;
          border-radius: 5px;
          background: #00009f;
          padding: 10px;
          font-size: 18px;
          color: #ffffff;
        }
      }
    }
  }
}

