.whoarewe-main {
  margin-top: 70px;
  background: #00009f;
  border-bottom: 1px solid #00009f;
  .hero-main {
    margin: 25px 0;
    padding: 25px;

    .whoarewe-header {
      margin: 30px 0 30px 0;
      h2 {
        text-align: center;
        color: #ffffff;
        font-weight: 700;
        font-size: 48px;
      }
    }

    .row {
      .col-md-6 {
        .image-section {
          img {
            background: rgba(0, 0, 0, 0.5);
            width: 550px;
            border-radius: 12px;
            
          }
        }
        .text-section {
          padding: 15px;
          margin: 5px;

          p {
            font-size: 18px;
            line-height: 24px;
            text-align: justify;
            color: #ffffff;
          }
        }
      }
    }
  }

  .hero-main2 {
    margin: 25px 0;
    // padding: 25px;
    background: #fff;

    .whoarewe-header2 {
      margin: 30px 0 30px 0;
      h2 {
        text-align: center;
        color: #00009f;
        font-weight: 700;
        font-size: 38px;
      }
    }

    .row {
      .col-md-6 {
        .image-section {
          img {
            background: rgba(0, 0, 0, 0.5);
            width: 550px;
            border-radius: 12px;
            
          }
        }
        .text-section2 {
          padding: 15px;
          margin: 5px;

          p {
            font-size: 18px;
            line-height: 24px;
            text-align: justify;
            color: #000;
          }

          ul{
            li{
              color:"000"
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .whoarewe-main {
    //display: none;
    .hero-main {
      padding: 2px;

      .row {
        .col-md-6 {
          .image-section {
            margin-bottom: 20px;
            img {
              max-width: 100%;
            }
          }
          .text-section {
            margin: 0;
            padding: 0;

            h2 {
              font-weight: 700;
              font-size: 18px;
              text-align: center;
            }

            p {
              font-size: 16px;
              line-height: 28px;
              //text-align: ;
            }
          }
        }
      }
    }
  }
}
