.partner-main {
    margin-bottom: 300px;
    border-top: 1px solid #00009f;
  .partner-title {
    text-align: center;
    color: #00009f;
    margin: 40px 0;

    h2 {
      font-weight: 800;
      font-size: 48px;
    }
  }

  .partners {

    .row {
      .col-md-4 {
        text-align: center;
        padding: 10px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 1px 1px rgba(201, 201, 201, 0.47);
        img {
          width: 250px;
          height: 250px;
        }
        :hover{
            opacity: 0.5;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .partner-main {
    margin-bottom: 50px;
    border-top: 1px solid #00009f;
  .partner-title {
    text-align: center;
    color: #00009f;
    margin: 30px 0;

    h2 {
      font-weight: 700;
      font-size: 38px;
    }
  }

  .partners {

    .row {
      .col-md-4 {
        text-align: center;
        padding: 10px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 1px 1px rgba(201, 201, 201, 0.47);
        img {
          width: 250px;
          height: 250px;
          
        }
        :hover{
            opacity: 0.7;
        }
      }
    }
  }
}

}
