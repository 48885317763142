.login-container {
  background: url("../assets/images/home.webp");
  background-size: "cover";
  height: 100vh;
  width: 100%;
  margin-top: 70px;
  background-repeat: no-repeat;
  .register-content {
    display: flex;
    width: 60%;
    margin: 50px auto;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

    .reg-form-section {
      padding: 10px 5px;
      width: 100%;
      // background: #fafcfc;
      background: #00009f;
      margin-top: 50px;
      border-radius: 10px;
      .form {
        width: 50%;
        margin: auto;
        padding: 40px 0;
        h3 {
          margin-bottom: 10px;
        }

        label {
          color: #fafcfc;
          font-size: 14px;
        }

        .forgetPassword {
          a {
            color: #fafcfc;
            font-size: 14px;
          }
        }
        .btn-section {
          display: flex;
          justify-content: flex-end;
          margin: 10px 0;
          .btn-submit {
            border: 1px solid #fff;
            text-align: center;
            border-radius: 10px;
            background: #00009f;
            padding: 5px 35px;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
      .signup {
        span {
          color: #fafcfc;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login-container {
    width: 100%;

    .register-content {
      width: 90%;
      .reg-form-section {
        padding: 50px 10px;
        margin-top: 0px;
        .form {
        
          width: 100%;
          h3 {
            font-size: 18px;
          }

          label {
            color: #fafcfc;
            font-size: 14px;
          }

          input {
            height: 40px;
            font-size: 14px;
          }
          .btn-section {
            .btn-submit {
              width: 100%;
            }
          }

          .signup {
            margin-bottom: 10px;
            span {
              color: #fafcfc;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .main-container {
    width: 100%;
    margin-top: 30px;

    .register-content {
      width: 100%;
      .reg-form-section {
        padding: 0;
        margin-top: 0;
        .form {
          .btn-section {
            .btn-submit {
              width: 100%;
              font-size: 12px;
              padding: 5px 25px;
            }
          }
        }
      }

      .signup {
        margin-bottom: 10px;
        span {
          color: #fafcfc;
          font-size: 12px;
        }
      }
    }
  }
}
