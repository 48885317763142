.faq-main {
  margin-top: 70px;

  .container {
    .faq-header {
      margin: 30px 0 30px 0;
      h2 {
        text-align: center;
        color: #00009f;
        font-weight: 700;
        font-size: 38px;
      }
    }

    .faqs {
      // border: 1px solid black;
      padding: 20px;
      p {
        font-size: 16px;
      }

      .btn-collapse {
                border: 1px solid #f2f2f2;
                background: #f8f6f6;
                width: 100%;
                text-align: left;
                padding: 8px 4px;
                font-size: 16px;
                border-radius: 5px;
              }

              .collapse {
                .pp {
                  font-size: 16px;
                  line-height: 32px;
                }
              }

      // .collapse-section{

      // }
    }
  }
}
