.services-main {
  margin-top: 70px;

  .container {
    .services-header {
      margin: 30px 0 30px 0;
      h2 {
        text-align: center;
        color: #00009f;
        font-weight: 700;
        font-size: 48px;
      }
    }
    .services {
      .row {
        .flip1 {
          background: #00009f;
          border: 1px solid #ffffff;
          border-radius: 10px;

          .service1 {
            color: #ffffff;
            padding: 10px;
            border-radius: 10;

            h3 {
              margin-bottom: 20px;
            }
          }

          animation-name: flip-in-ver-left;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-delay: 0s;
          animation-iteration-count: 1;
          animation-direction: normal;
          animation-fill-mode: none;

          /* shorthand
		animation: flip-in-ver-left 1s linear 0s 1 normal none;*/
        }

        .flip2 {
          background: #00009f;
          border: 1px solid #ffffff;
          border-radius: 10px;

          .service2 {
            color: #ffffff;
            padding: 10px;
            border-radius: 10;

            h3 {
              margin-bottom: 20px;
            }
          }

          animation-name: scale-up-center;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-delay: 1s;
          animation-iteration-count: 1;
          animation-direction: normal;
          animation-fill-mode: none;

          /* shorthand
		animation: flip-in-ver-left 1s linear 0s 1 normal none;*/
        }

        .flip3 {
          background: #00009f;
          border: 1px solid #ffffff;
          border-radius: 10px;

          .service3 {
            color: #ffffff;
            padding: 10px;
            border-radius: 10;

            h3 {
              margin-bottom: 20px;
            }
          }

          animation-name: scale-up-center;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-delay: 2s;
          animation-iteration-count: 1;
          animation-direction: normal;
          animation-fill-mode: none;

          /* shorthand
		animation: flip-in-ver-left 1s linear 0s 1 normal none;*/
        }

        .flip4 {
          background: #00009f;
          border: 1px solid #ffffff;
          border-radius: 10px;
          
          .service4 {
            color: #ffffff;
            padding: 10px;
            border-radius: 10;

            h3 {
              margin-bottom: 20px;
            }
          }

          animation-name: scale-up-center;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-delay: 3s;
          animation-iteration-count: 1;
          animation-direction: normal;
          animation-fill-mode: none;

          /* shorthand
		animation: flip-in-ver-left 1s linear 0s 1 normal none;*/
        }
      }
    }

    .whyrockbank {
      margin-top: 50px;
      padding: 20px 90px 200px 90px;
      background: #fafcfc;
      // color: #ffffff;
      border-radius: 8px;
      h2 {
        font-weight: 700;
        // font-size: 38px;
        margin: 0 0 20px 0;
        color: #00009f;
      }

      .row {
        .col-md-4 {
          // margin-top: 40px;
          p {
            font-size: 18px;
            text-align: justify;
          }
        }
      }

      .overview {
        font-style: italic;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .services-main {
    .container {
      .whyrockbank {
        padding: 20px;
        h2 {
          font-size: 40px;
        }

        .review {
          h2 {
            font-size: 40px;
          }
        }
      }
    }
  }
}

@keyframes flip-in-ver-left {
  0% {
    transform: rotateY(80deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0);
    opacity: 1;
  }
}

@keyframes scale-up-center {
			
		0% {
			transform:scale(.2);
		}
		100% {
			transform:scale(1);
		}
	}
