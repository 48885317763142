.main-container {
  background: url("../assets/images/home.webp");
  background-size: "cover";
  height: 100vh;
  width: 100%;
  margin-top: 70px;
  background-repeat: no-repeat;
  .register-content { 
    display: flex;
    width: 60%;
    margin: 70px auto;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    
    .reg-form-section {
      padding: 10px 5px;
      width: 100%;
      // background: #fafcfc;
      background: #00009f;
      margin-top: 30px;
      border-radius: 10px;
      height: 350px;

      .form {
        width: 50%;
        margin: 40px auto;

        h3 {
          margin-bottom: 10px;
        }

        label{
          color: #fafcfc;
          font-size: 14px;
        }

        input{
          height: 35px;
        }

        .btn-section {
          display: flex;
          justify-content: flex-end;
          .btn-submit {
            border: 1px solid #fff;
            text-align: center;
            border-radius: 10px;
            background: #00009f;
            padding: 5px 35px;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
      .login{
        span{
          color: #fafcfc;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-container {
    width: 100%;

    .register-content {
      width: 90%;
      .reg-form-section {
        padding: 50px 0;
        .form {
          padding: 10px;
          label{
          color: #fafcfc;
          font-size: 14px;
        }

        input{
          height: 35px;
          font-size: 14px;
        }
          .btn-section {
            .btn-submit {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .main-container {
    width: 100%;
    margin-top: 30px;

    .register-content {
      
      .reg-form-section {
        padding: 0;
        margin-top: 0;
        .form {
          width: 100%;
          padding: 10px;
          .btn-section {
            .btn-submit {
              width: 100%;
              font-size: 12px;
              padding: 5px 35px;
            }
          }
        }
      }

      .login{
        font-size: 12px;
        margin-bottom: 10px;
        span{
          color: #fafcfc;
        }
      }
    }
  }
}

