.main-desktop {
  margin-top: 70px;
  .hero-main {
    display: flex;
    background: #ffffff;
    margin: 25px 0;
    padding: 25px;

    .text-section {
      padding: 15px;

      p {
        font-size: 18px;
        line-height: 24px;
        text-align: justify;
      }

      h2{
        font-size: 42px;
      }

      .txt-min {
        display: none;
      }

      .btn-max {
        border: 1px solid #00009f;
        width: 50%;
        text-align: center;
        border-radius: 10px;
        background: #00009f;
        padding: 5px 35px;
        font-size: 22px;

        a {
          border: none;
          color: #ffffff;
          font-style: italic;
          text-decoration: none;
        }

        :hover {
          opacity: 0.5;
        }
      }
    }

    .image-section {
      img {
        background: rgba(0, 0, 0, 0.5);
        width: 550px;
        border-radius: 12px;
        height: 100%;
      }
    }
    
  }
  
}

@media screen and (max-width: 768px) {
  .main-desktop {
    display: none;
    .hero-main {
      padding: 2px;

      .text-section {
        position: absolute;
        top: 60px;
        z-index: 2;
        color: #ffffff;
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.5);
        text-align: center;

        h2 {
          font-weight: 700;
          font-size: 18px;
          text-align: center;
        }

        p {
          font-size: 16px;
          line-height: 28px;
          text-align: center;
          // font-weight: 700;
        }

        .txt-max {
          display: flex;
        }

        .txt-min {
          display: none;
        }
        .btn-max {
          display: flex;
          justify-content: center;

          button {
            border: none;
            font-size: 14px;
            color: #ffffff;
            background: #00009f;
            border-radius: 10px;
            padding: 4px 35px;
            font-style: italic;
          }
        }
      }

      .image-section {
        img {
          max-width: 100%;
        
        }
      }
    }
  }
}
