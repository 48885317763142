nav {
  z-index: 3;
  width: 100%;
  background: #ffffff;
  margin-bottom: 20px;
  border-bottom: 2px solid #00009f;

  .container {
    .brand-section {
      margin: 0px 20px 0px 0;
    }
    .navbar-toggler {
      background-color: #ffffff;
    }
    .nav-items {
      ul {
        li {
          font-size: 18px;
          margin-right: 10px;
          font-weight: 500;

          a {
            color: #00009f;
          }
          :hover {
            color: red;
            cursor: pointer;
          }
        }

        .home-desktop {
          display: block;
        }
        .home-mobile {
          display: none;
        }
      }
      .login-section {
        border: 1px solid white;
        display: flex;
        li {
          list-style: none;
          font-size: 18px;
          font-weight: 500;
        }
      }
      
    }

    .button-section {
      // border: 1px solid white;
        .btn-login {
          background: #00009f;
          color: #ffffff;
          font-size: 16px;
          border-radius: 8px;
          border: none;
          padding: 5px 15px;
          margin: 2px;
        }


        .btn-signup {
          background: #00009f;
          color: #ffffff;
          font-size: 16px;
          border-radius: 8px;
          border: none;
          padding: 5px 15px;
          margin: 2px;
        }

        .btn-secure {
          
          .btn-logout {
            font-size: 16px;
            border-radius: 8px;
            border: none;
            padding: 5px 15px;
            background: #00009f;
            color: #ffffff;
            margin: 2px;
          }

          .btn-dashboard{
            background: #00009f;
            color: #ffffff;
            font-size: 18px;
            // font-weight: 500;
            border: none;
            padding: 5px 15px;
            margin-right: 8px;
            border-radius: 8px;
            margin: 2px;
          }
        }

        :hover {
          opacity: 5;
        }
      }
  }
}

@media screen and (max-width: 768px) {
  nav {
    .container {
      .nav-items {
        ul {
          .home-desktop {
            display: none;
          }

          .home-mobile {
            display: block;
          }
        }
      }
      .button-section {
        
        
        .btn-secure {
          display:inline-block;
          

          .btn-dashboard{
            background: #00009f;
            color: #ffffff;
            font-size: 18px;
            font-weight: 500;
            border: none;
            padding: 5px 15px;
            margin-right: 10px;
          }
        }

        :hover {
          opacity: 5;
        }
      }
    }
  }
}
