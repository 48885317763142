.plan-container {
  background: url("../assets/images/home.webp");
  background-size: "cover";
  height: 100vh;
  width: 100%;
  margin-top: 70px;
  background-repeat: no-repeat;
  .container {
    background: #00009f;
    margin-top: 120px;

    .caption {
    margin-top: 50px; 
      color: #ffffff;
      padding: 20px ;
        p{
          font-size: 22px;
          text-align: justify;
        }
    }

    .plan-form-section {
      padding: 10px 5px;
      width: 100%;
      background: #00009f;
      border-radius: 10px;

      .form {
        width: 100%;
        margin: auto;
        padding: 20px;

        label {
          color: #fafcfc;
          font-size: 14px;
        }

        input {
          height: 35px;
        }

        .btn-section {
          display: flex;
          justify-content: flex-end;
          margin: 10px 0;
          .btn-submit {
            border: 1px solid #fff;
            text-align: center;
            border-radius: 10px;
            background: #00009f;
            padding: 5px 35px;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
      .login {
        span {
          color: #fafcfc;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .plan-container {
    width: 100%;
    margin-bottom: 180px;
    .container {
      width: 90%;
      margin-top: 0px;

      .caption{
        p{
            font-size: 16px;
        }
      }
      .plan-form-section {
        
        padding: 0;
        .form {
            padding: 0;
          label {
            color: #fafcfc;
            font-size: 14px;
          }

          input {
            height: 30px;
            font-size: 14px;
          }
          .btn-section {
            .btn-submit {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .plan-container {
    width: 100%;
    margin-top: 30px;

    .plan-content {
      .plan-form-section {
        padding: 0;
        margin-top: 0;
        .form {
          width: 100%;
          .btn-section {
            .btn-submit {
              width: 100%;
              font-size: 12px;
              padding: 5px 35px;
            }
          }
        }
      }

      .login {
        font-size: 12px;
        margin-bottom: 10px;
        span {
          color: #fafcfc;
        }
      }
    }
  }
}
