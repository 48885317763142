.contact-main {
  border-top: 1px solid #00009f;

  .container {
    background: #00009f;
    padding: 60px;
    color: #ffffff;
    .form-section {
      width: 50%;
      margin: 20px auto;
      padding: 10px;

      .form-text {
        color: #ffffff;
        font-size: 12px;
      }
    }

    .contact-title {
      text-align: center;
      span {
        font-size: 24px;
        font-style: italic;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-main {
    .container {
      background: #00009f;
      padding: 10px;
      color: #ffffff;
      .form-section {
        width: 100%;
        margin: 20px auto;
        padding: 10px;
      }

      .contact-title {
        span {
          font-size: 18px;
          font-style: italic;
        }
      }
    }
  }
}
