.retail-main {
  margin-top: 70px;
//   background: #fafcfc;
  padding: 25px;

  .container {
    .comming-soon{
      text-align: center;
      padding: 50px;
    }
  }
}
