.background-video-container {
  margin-top: 50px;
  padding: 25px;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .content-overlay {
    position: relative;
    z-index: 1;
    color: white;
    // text-align: center;
    padding: 20px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    .container {
      .form-section {
        border: 1px solid rgba(201, 201, 201, 0.47);
        width: 60%;
        margin: auto;
        padding: 15px;
        text-align: left;
        border-radius: 10px;
        background: #00009f;
        height: 500px;

        form {
          margin: 25px;

          label {
            font-size: 18px;
          }

          .btn-section {
            // border: 1px solid black;
            .btn-submit {
              border: 1px solid #fff;
              // width: 100%;
              text-align: center;
              border-radius: 10px;
              background: #00009f;
              padding: 5px 35px;
              font-size: 18px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .background-video-container {
     .content-overlay{
      .container {
      .form-section {
        width: 100%;
      }
    }
     }
    
  }
}
