.footer {
  margin-top: 20px;
  .main {
    background: #fafcfc;
    padding: 30px 0;
    .container {
      .row {
        padding: 20px;
        .col-md-2 {
          img {
            width: 150px;
          }
        }

        .col-md-6 {
          h5 {
            margin-left: 25px;
            color: #00009f;
          }
          ul {
            li {
              line-height: 32px;
              list-style: none;

              span {
                font-size: 22px;
                margin-right: 5px;
                color: #00009f;
              }
            }
          }
        }
        .col-md-2,
        .col-md-3 {
          h5 {
            margin-left: 25px;
            color: #00009f;
          }
          ul {
            li {
              line-height: 32px;
              list-style: none;
              a {
                text-decoration: none;
                color: #494848;

                span {
                  font-size: 18px;
                  margin-right: 5px;
                  color: #00009f;
                }
              }
              :hover {
                color: #ecb4b4;
              }
            }
          }
        }
      }
      .sub-section{
     
      p{
        text-align: center;
      }
    }
    }
  }
  .copyright {
    text-align: center;
    background: #00009f;
    font-size: 12px;
    color: #ffffff;
    padding: 20px 0;
    span {
      margin: 40px 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    // border: 1px solid red;
    // position: absolute;
    // top: 138em;
    width: 100%;
    .main {
      background: #fafcfc;
      padding: 10px 0;
      .container {
        .row {
          .col-md-2,
          .col-md-3,.col-md-6 {
            img {
              display: none;
            }

            ul {
              li {
                font-size: 14px;
                  span {
                  font-size: 16px;
                }
              
              }
            }
          }
        }
      }
    }
  }
}
